<template>
    <NoButtonModal :open="true">
        <button class="close-corner-x" type="button" @click.prevent="$emit('cancel')">
            <span class="material-icons-outlined dark-text">close</span>
        </button>
        <h1>Deny Members</h1>
        <p>Are you sure you want to denying selected members?</p>
        <button @click="$emit('cancel')" class="button secondary">Cancel</button>
        <button @click="$emit('handleDeny', 'Denied')" class="button primary">Deny</button>
    </NoButtonModal>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';

    export default {
        name: 'DenyMemberModal',
        components: { NoButtonModal },
    };
</script>
