<template>
    <div class="page-body clone-dashboard">
        <NoButtonModal :open="true">
            <div class="modal-content-header bottom-20">
                <span style="font-size: 20px; font-weight: bold; margin-left: 20px">Approve Members</span>
            </div>
            <div v-if="!loading" class="d-flex flex-column align-items-center" style="gap: 10px">
                <div class="w-80 mx-4 d-flex justify-content-center flex-column">
                    <label for="primaryClinician" class="add-new-app-label my-2 class-label">Primary Clinician*</label>
                    <Multiselect
                        id="primaryClinician"
                        track-by="id"
                        label="name"
                        class="position-relative"
                        placeholder="Select Clinician"
                        v-model="primaryClinician"
                        :options="showClinicianList(secondaryClinician)"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        required
                    />
                </div>
                <div class="w-80 mx-4 d-flex justify-content-center flex-column">
                    <label for="secondaryClinician" class="add-new-app-label my-2 class-label"
                        >Secondary Clinician*</label
                    >
                    <Multiselect
                        id="secondaryClinician"
                        track-by="id"
                        label="name"
                        class="position-relative"
                        placeholder="Select Clinician"
                        v-model="secondaryClinician"
                        :options="showClinicianList(primaryClinician)"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        required
                    />
                </div>
                <div class="w-80 mx-4 d-flex justify-content-center flex-column">
                    <label for="tags" class="add-new-app-label my-2 class-label">Project Tags</label>
                    <Multiselect
                        id="tags"
                        track-by="name"
                        label="name"
                        placeholder="Select Tags"
                        v-model="tags"
                        :options="tagsList"
                        :searchable="true"
                        :show-labels="false"
                        :multiple="true"
                        :clear-on-select="false"
                        :close-on-select="false"
                    />
                </div>
                <div class="my-4 d-flex justify-content-center" style="bottom: 10%">
                    <button type="button" class="primary btn-save" @click="submit">Approve</button>
                    <button type="button" class="no-bg btn-cancel px-4" @click="$emit('cancel')">Cancel</button>
                </div>
            </div>
            <div v-if="loading" class="my-4 d-flex flex-column justify-content-center align-items-center loader-main">
                <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
                <span class="ms-3">Loading ...</span>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { companies, users } from '@/util/apiRequests';

    export default {
        name: 'UnapprovedMemberModal',
        components: { NoButtonModal },
        data() {
            return {
                loading: true,
                cliniciansList: [],
                tagsList: [],
                primaryClinician: '',
                secondaryClinician: '',
                tags: '',
            };
        },
        methods: {
            showClinicianList(type) {
                return this.cliniciansList.filter((item) => item.id !== type?.id);
            },
            async getClinicians() {
                try {
                    const res = await this.$api.get(users.getStarkidsClinician());
                    this.cliniciansList = res.data.response.map((item) => {
                        item.name = item.first_name + ' ' + item.last_name;
                        return item;
                    });
                } catch (err) {
                    this.$toasted.error('Could not retrieve the list of clinicians');
                }
            },
            async getTags() {
                try {
                    const res = await this.$api.get(companies.getTags(this.$store.state.user.company_id));
                    this.tagsList = res.data.info;
                    this.tags = res.data.info.filter((tag) => tag.name.toLowerCase().includes('star kids'));
                    if (!this.tags.length) {
                        this.tags = '';
                    }
                } catch (err) {
                    console.log('err', err);
                    this.$toasted.error('Could not retrieve the list of Tags');
                }
            },
            submit() {
                if (!this.primaryClinician?.id || !this.secondaryClinician?.id) {
                    return this.$toasted.error('Please select primary and secondary clinician to procced!');
                }
                const data = {
                    primaryClinician: this.primaryClinician?.id,
                    secondaryClinician: this.secondaryClinician?.id,
                    tags: this.tags,
                };
                this.$emit('handleApprove', 'Active', data);
            },
        },
        async created() {
            await this.getClinicians();
            await this.getTags();
            this.loading = false;
        },
    };
</script>

<style scoped>
    .modal-content-header {
        background-color: #f1f7ff;
        padding: 10px;
        display: flex;
        align-items: center;
    }

    .btn-save {
        border-radius: 10px;
        background: #5155c3 !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    .text-link:hover {
        background: #5155c3;
    }

    button.no-bg:hover {
        background: none;
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }
</style>

<style>
    .clone-dashboard .modal__content {
        padding: 0;
        border-radius: 10px;
        min-height: fit-content;
    }

    .clone-dashboard .class-label {
        color: #666c6d;
        font-size: 1rem;
        font-weight: 600;
    }
</style>
